import BreakoutHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/breakout-history/BreakoutHistoryTemplate';

import { BOOLEAN, GENDER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/BreakoutHistory/BreakoutHistoryTemplate',
  component: BreakoutHistoryTemplate
};

const createStory = props => () => ({
  components: { BreakoutHistoryTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><breakout-history-template v-bind="$options.storyProps"/></div>'
});

export const YesAnswerWomen = createStory({
  treatedForAcne: BOOLEAN.YES,
  isTreatedForAcne: true,
  gender: GENDER.FEMALE,
  acneTreatments: ['benzoyl_peroxide', 'ta_erythromycin', 'oral_contraceptives']
});

export const YesAnswerMen = createStory({
  treatedForAcne: BOOLEAN.YES,
  isTreatedForAcne: true,
  gender: GENDER.MALE,
  acneTreatments: ['benzoyl_peroxide', 'ta_erythromycin']
});

export const NoAnswer = createStory({
  treatedForAcne: BOOLEAN.NO
});
